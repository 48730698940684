import React, { Component } from 'react'
import { Link } from "gatsby"

import SEO from "../../components/seo"

import ReactPlayer from 'react-player'
import ReactModal from 'react-modal'
import Div100vh from '../../utils/Div100vh'
import Close from "../../svg/Close"


import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TrackVisibility from "react-on-screen"


const settings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true
}

export default class article extends Component {
    constructor() {
        super()
        this.state = {
            quickLookMode: false,
            modalIsClosing: false,
            playing: false
        }
        // event for toggling modal with quickview mode
        // this.toggleMode = this.toggleMode.bind(this)
    }

    listMedias() {
        
        let slides = []
        {this.props.pageContext.node.data !== null && (this.props.pageContext.node.data.map(workData => {
            if (workData.dataType === "core/image") {
                let wokrImage;
                if (workData.image.large) {
                    wokrImage = workData.image.large;
                }
                else {
                    wokrImage = workData.image.full;
                }   
                slides.push(
                    <div className="img__Block" style={{backgroundImage:`url("${wokrImage}")`}}></div>
                )
            }
            if (workData.dataType === "core/gallery") {
                {workData.gallery.map(galleryImage => {
                    let galleryUrl;
                    if (galleryImage.large) {
                        galleryUrl = galleryImage.large;
                    }
                    else {
                        galleryUrl = galleryImage.full;
                    }   
                    slides.push(
                      <div className="img__Block" style={{backgroundImage:`url("${galleryUrl}")`}}></div>
                    )
                    // slides.push(
                    //     <div className="img__Block" style={{backgroundImage:`url("${galleryImage.large.sourceUrl}")`}}></div>
                    // )
                })}
            }
            if (workData.dataType === "core/video") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={workData.videoURL.sourceUrl} muted={true} controls={true} />
                    </div>
                )
            }
            if (workData.dataType === "core-embed/youtube" || workData.embedType === "youtube") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={`https://www.youtube.com/embed/${workData.embedURL}`} controls={true} />
                    </div>
                )
            }
            if (workData.dataType === "core-embed/vimeo" || workData.embedType === "vimeo") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={`https://player.vimeo.com/video/${workData.embedURL}?background=1`} controls={true}  />
                    </div>
                )
            }
            
        }))}

        return slides.map((slide, index) => (
        <div key={index} className="work__single__quickview__slide">
            <div className="inner">{slide}</div>
        </div>
        ))
    }

    langSwitcher(){
        let langKey = []
        if (this.props.pageContext.node.language_code === "en") {
            langKey.splice(0,0,
                <Link to={`en/news/${this.props.pageContext.node.slug}`} className="active">ENG</Link>
            )
        }
        if (this.props.pageContext.node.language_code === "ja") {
            langKey.splice(1,0,
                <>/<Link to={`/news/${this.props.pageContext.node.slug}`} className="active">JPN</Link></>
            )
        }
        if (this.props.pageContext.node.language_code === "ko") {
            langKey.splice(2, 0,
                <>/<Link to={`/ko/news/${this.props.pageContext.node.slug}`} className="active">KOR</Link></>
            )
        }
        {this.props.pageContext.node.wpml_translations !== null && (this.props.pageContext.node.wpml_translations.map(languages => {
            if (languages.locale === "en_US") {
                langKey.splice(0, 0,
                    <Link to={`/en/news/${this.props.pageContext.node.slug}`}>ENG</Link>
                )
            }
            if (languages.locale === "ja_JP") {
                langKey.splice(1,0,
                    <>/<Link to={`/news/${this.props.pageContext.node.slug}`}>JPN</Link></>
                )
            }
            if (languages.locale === "ko_KR") {
                langKey.splice(2, 0,
                    <>/<Link to={`/ko/news/${this.props.pageContext.node.slug}`}>KOR</Link></>
                )
            }
        }))}
        return langKey
    }


    toggleMode(e = null, modalIndex = 0) {
        if (e) e.preventDefault()
        this.setState({
            quickLookMode: !this.state.quickLookMode ? true : false,
            modalIsClosing: false,
            modalIndex: modalIndex,
        })
    }

    closeModal(e = null) {
        if (e) e.preventDefault()
        if (!this.state.modalIsClosing) {
            this.setState({
                modalIsClosing: true,
            })
            setTimeout(() => {
                this.toggleMode(e)
            }, 1000)
        }
    }
    modalClassName(base = `modal`) {
        let classes = [base]
        if (this.state.modalIsClosing) {
        classes.push(`is-closing`)
        }
        return classes.join(` `)
    }
    playerIsVisible({isVisible}){
        // console.log(isVisible)
    }

render() {
        const ComponentToTrack = props => {
          return (
              <div className="block iframe">
                  <ReactPlayer
                      url={props.URL}
                      playing={props.isVisible}
                      controls={true}
                      muted={true}
                      loop={true}
                      width="100%"
                      height="100%"
                  />
              </div>
          )
        }
        const langKey = this.props.pageContext.urlBase
        // const urlBase = langKey === "en" ? "" : `${langKey}/`
        const urlBase = langKey === "ja" ? "" : `${langKey}/`
        // const urlBase = ``
      return (

        <>
            <SEO title={this.props.pageContext.title} />
            <article>
                {/* <Spotlight image={require(`../medias/img/article/article-spotlight.jpg` )} /> */}
                <section className="container-fluid-custom-only work_detail_page top__margin min--width--coloumn--work preview--pad--tb">
                    <h1 dangerouslySetInnerHTML={{__html:this.props.pageContext.node.title}} />
                    <div className="work_detail_page__Legend">
                    <ul>
                      {this.props.pageContext.node.date &&
                        <li>Date: {this.props.pageContext.node.date}</li>
                      }
                    </ul>
                    
                    </div>
                    {/* <div className="work_detail_page__Para" dangerouslySetInnerHTML={{__html:pageContext.node.content}} /> */}
                    {this.props.pageContext.node.data !== null && (this.props.pageContext.node.data.map((workContent, index) => {
                    
                        
                        return(
                            <React.Fragment key={index}>
                                {workContent.dataType === "core/paragraph" && (
                                    <div className="work_detail_page__Para" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                )}
                                {workContent.dataType === "core/list" && (
                                    <p dangerouslySetInnerHTML={{__html:workContent.content}}></p>
                                )}
                                {workContent.dataType === "core/work-result" && (
                                    <div className="work_detail_page__Para">
                                        <h3>Results</h3>
                                        <p dangerouslySetInnerHTML={{__html:workContent.content}} />
                                    </div>
                                )}
                                {workContent.dataType === "core/image" && workContent.image.full !== null && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block">
                                            <a href="true" onClick={e => this.toggleMode(e)}><img src={workContent.image.full} alt="" /></a>
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/gallery" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block gallery">
                                            {workContent.gallery.map(galleryItem => {
                                                // console.log(galleryItem.full)
                                                return(
                                                    <>
                                                    {galleryItem.full !== null && (<div className="gallery__Item" key={galleryItem.full.id}>
                                                        <a href="true" onClick={e => this.toggleMode(e)}><img src={galleryItem.full} alt="" /></a>
                                                    </div>)}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/columns" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block gallery">
                                            {workContent.videoURLS.map(galleryItem => {
                                                return(
                                                    <div className="gallery__Item" key={galleryItem}>
                                                        <ReactPlayer url={galleryItem} width='100%' height='100%' controls={false} playing loop={true}  />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/media-text" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block mediaText">
                                            {workContent.mediaPosition === "right" && (
                                                <>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.full} alt="" /></a></div>
                                                </>
                                            )}
                                            {workContent.mediaPosition === "left" && (
                                                <>
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.full} alt="" /></a></div>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                </>
                                            )}
                                            {workContent.mediaPosition === null && (
                                                <>
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.large} alt="" /></a></div>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/video" && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={workContent.videoURL.sourceUrl} />
                                        </TrackVisibility>
                                    </div>
                                )}
                                {(workContent.dataType === "core-embed/youtube" || workContent.embedType === "youtube") && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={`https://www.youtube.com/embed/${workContent.embedURL}`} />
                                        </TrackVisibility>
                                    </div>
                                )}
                                {(workContent.dataType === "core-embed/vimeo" || workContent.embedType === "vimeo") && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={`https://player.vimeo.com/video/${workContent.embedURL}?background=1`} />
                                        </TrackVisibility>
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    }))}











                </section>
                {/* {this.props.pageContext.node.navigation && (<section className="browse_More">
                    {this.props.pageContext.node.navigation.pre_title && (<Link to={`/${urlBase}culture/${this.props.pageContext.node.navigation.pre_slug}`} className="browse_More__previous">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><title>{this.props.pageContext.node.navigation.pre_title}</title><path d="M1 7.75h16v1.5H1z"></path><path d="M8.5 17L0 8.5 8.5 0l1.1 1.1-7.5 7.4L9.6 16z"></path></svg>
                        <div className="browse_More__previous__title">{this.props.pageContext.node.navigation.pre_title}</div>
                    </Link>)}
                    <h3>Browse More</h3>
                    {this.props.pageContext.node.navigation.next_title && (<Link to={`/${urlBase}culture/${this.props.pageContext.node.navigation.next_slug}`} className="browse_More__next">
                        <div className="browse_More__next__title">{this.props.pageContext.node.navigation.next_title}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><title>{this.props.pageContext.node.navigation.next_title}</title><path d="M1 7.75h16v1.5H1z"></path><path d="M8.5 17L0 8.5 8.5 0l1.1 1.1-7.5 7.4L9.6 16z"></path></svg>
                    </Link>)}
                </section>)} */}
                {/* {this.props.pageContext.node.related_posts && (<section className="related_article_carousel">
                    <div className="slick_slider container-fluid-custom-only">
                        <h3>Related articles</h3>
                        <div className="slider">
                            <Slider {...settings}>
                                {this.props.pageContext.node.related_posts.map(relatedpost => {
                                    return(
                                        <div className="slides" key={relatedpost.id}>
                                            <div className="box_wrap">
                                                <Link to={`/${urlBase}news/${relatedpost.slug}`}>
                                                    <div className="image"><img src={relatedpost.image.sourceUrl} alt="" /></div>
                                                    <p>{relatedpost.name}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </section>)} */}
            </article>
            {/* <div className="gatsby__build_debug">
                <div className="container-fluid-custom-only">Viewing site in preview mode. Last draft save time was: {this.props.pageContext.node.date}</div>
            </div> */}
             {/* All work model */}
             <ReactModal 
                className={this.modalClassName(`modal__main`)}
                overlayClassName={this.modalClassName(`modal__overlay`)}
                onRequestClose={e => this.closeModal(e)}
                isOpen={this.state.quickLookMode}
                >
                <Div100vh>
                    <div className="modal__inner">
                        <a
                        href="true"
                        className="modal__close"
                        onClick={e => this.closeModal(e)}
                        >
                        <Close />
                        </a>
                        <header className="modal__header is-text-center">
                            <h5 className="no-margin" dangerouslySetInnerHTML={{__html:this.props.pageContext.node.title}} />
                        </header>
                        <div className="modal__content">
                        <div ref="slider" className="work__single__quickview">
                            <Slider {...settings}>
                                {this.listMedias()}
                            </Slider>
                        </div>
                        </div>
                    </div>
                </Div100vh>
            </ReactModal>
        </>
        )
    }
}

